import dayjs from 'dayjs'
import isNil from 'lodash/isNil'
import mapValues from 'lodash/mapValues'

import { ProductGroup as ProductGroupName, ReturnReason } from 'src/constants'
import Contact, { ContactType } from 'src/models/contact'
import { DeliveryConfirmationCode } from 'src/models/delivery'
import { Notification } from 'src/models/notification'
import {
  CBOrderMetadata,
  Order,
  OrderPreview,
  Status as OrderStatus,
  OrderStatusDetails,
  Priority,
  PriorityNext,
  RequestedProduct,
} from 'src/models/order'
import {
  BasePackage,
  BasicPackage,
  DailyFacilitySummary,
  DailyLocationSummary,
  Package,
  PackageStatusDetails,
} from 'src/models/package'
import {
  Attribute,
  AttributeMap,
  AttributeType,
  AttributeValue,
  Product,
  ReceiveType,
} from 'src/models/product'
import { ProductGroup } from 'src/models/product_group'
import { ProofOfDelivery } from 'src/models/proof_of_delivery'
import {
  Shipment,
  ShipmentPreview,
  ShipmentPreviewUnits,
  Status as ShipmentStatus,
  ShipmentUnit,
} from 'src/models/shipment'
import {
  DeliverySite,
  FacilityStock,
  HealthFacility,
  HealthFacilityGroup,
  HealthFacilityLocation,
  HealthFacilityPreview,
  OrderStatusHealthFacility,
} from 'src/models/sites'
import { Supplier } from 'src/models/supplier'
import {
  ReceivedShipment,
  ReceivedShipmentSummary,
  ReturnedShipment,
  ReturnedShipmentSummary,
} from 'src/models/supplier_shipment'
import {
  BasicUnit,
  BasicUnitWithProduct,
  BasicUnitWithReturnDate,
  Unit,
  UnitAction,
  UnitBox,
  UnitStatus,
} from 'src/models/unit'
import { FulfillmentOperator, Orderer } from 'src/models/users'
import { NewDeliverySite } from 'src/services/DeliverySiteService'
import { NewHealthFacility } from 'src/services/HealthFacilityService'
import { computeOrderSubstatus } from 'src/utils/order'
import { transformUser } from 'src/utils/userTransformers'

// TODO: type the expected responses

interface CBOrderMetadataResponse {
  priority_experimental: PriorityNext
  delivery_window_start: string
  delivery_window_end: string
  time_begin_packing: string
}

interface OrderPreviewResponse {
  id: string
  priority: Priority
  priority_reasons?: string[]
  product_groups: ProductGroupName[]
  time_begin_packing: string
  time_created: string
  time_scheduled?: string
  delivery_window_start?: string
  delivery_window_end?: string
  time_customer_notified_credit_check_failed: string | null
  sla_time: string
  facility: HealthFacilityLocation
  status: OrderStatus
  shipments?: number
  notification_count?: number
  comments?: string
  delivery_site: DeliverySite
  credit_check_override_reason: string | null
  cb_meta?: CBOrderMetadataResponse
  time_pod_signed?: string | null
}

export interface ContactPayload {
  id?: number
  name: string
  email?: string
  phone_number?: string
  is_default_msg_recipient: boolean
  contact_type?: ContactType
  facility?: number // primary key of facility
  district?: string // name of district
}

export interface OrdererPayload {
  username: string
  name: string
  display_name: string
  email?: string
  phone_number: string
  facility_id: number
  is_default_msg_recipient: boolean
  is_active: boolean
}

interface OrdererResponse extends OrdererPayload {
  id: number
}

interface ShipmentPreviewResponse {
  id: string
  order: OrderPreviewResponse
  time_created: string
  status: ShipmentStatus
  locked: boolean
  packers: FulfillmentOperator[]
  package_ids?: string[]
  product_groups: ProductGroupName[]
  id_in_order?: number
  time_scheduled?: string
  launch_window_start?: string
  launch_window_end?: string
  launch_is_urgent_after?: string
  time_begin_packing?: string
  clear_for_launch?: boolean | null
  wind_speed?: number | null
  battery_dot_rating?: number | null
}

export function transformOrderer(orderer: OrdererResponse): Orderer {
  return {
    ...transformUser(orderer),
    facilityId: orderer.facility_id,
    recipientIds: [orderer.facility_id],
    phoneNumber: orderer.phone_number,
    displayName: orderer.display_name,
    isDefaultMsgRecipient: orderer.is_default_msg_recipient,
    isActive: orderer.is_active,
  }
}

export function transformOrdererPayload(orderer: Orderer): OrdererPayload {
  return {
    username: orderer.username,
    name: orderer.name,
    display_name: orderer.displayName,
    email: orderer.email,
    phone_number: orderer.phoneNumber,
    facility_id: orderer.facilityId,
    is_default_msg_recipient: orderer.isDefaultMsgRecipient,
    is_active: orderer.isActive,
  }
}

export function transformOrder(order: any): Order {
  const { orderer, recipient, requested_products } = order

  const requestedProducts: RequestedProduct[] = requested_products.map(
    (request: RequestedProduct) => transformRequestedProduct(request)
  )

  const ordererData = transformOrderer(orderer)
  const orderData: Order = {
    ...transformOrderPreview(order),
    externalUuid: order.external_uuid,
    orderer: ordererData,
    facility: transformHealthFacilityPreview(orderer.facility),
    recipientRoleId: orderer.facility_id,
    orderMethod: order.order_method,
    offNominalReason: order.off_nominal_reason,
    packageIds: order.package_pks,
    priorityReasons: order.priority_reason,
    pharmacistName: order.pharmacist_name,
    pharmacistId: order.pharmacist_id,
    recipient: recipient ? transformContact(recipient) : undefined,
    referringPhysicianId: recipient?.id,
    requestedProducts,
    requiresPayment: order.requires_payment,
    timePaymentReceived: order.time_payment_received,
    timeCustomerNotifiedReceived: order.time_customer_notified_order_received,
    timeCustomerNotifiedConfirmed: order.time_customer_notified_order_confirmed,
    timeCustomerNotifiedCreditCheckFailed:
      order.time_customer_notified_credit_check_failed,
    timeCustomerNotifiedOutOfStock:
      order.time_customer_notified_order_out_of_stock,
    comments: order.comments,
    deliverySite: isNil(order.delivery_site) ? undefined : order.delivery_site,
    possibleDeliverySites: order.possible_delivery_sites.map(
      transformDeliverySite
    ),
    facilityCreditCheckStatus: order.facility_credit_check_status,
  }

  return orderData
}

function mapStatus(
  status:
    | OrderStatus
    | 'processing'
    | 'packaging'
    | 'alternate_delivering'
    | 'launching'
    | 'in_flight'
): OrderStatus {
  switch (status) {
    // TODO: Remove this after one Fulfillment release for backwards compatibility.
    // This is because old statuses might still exist in the browser cache.
    case 'processing':
    case 'packaging':
    case 'alternate_delivering':
    case 'launching':
    case 'in_flight':
      return OrderStatus.CONFIRMED
    default:
      return status
  }
}

function transformCBOrderMetadata(
  cbMeta?: CBOrderMetadataResponse
): CBOrderMetadata | undefined {
  if (!cbMeta) return undefined
  return {
    priorityExperimental: cbMeta.priority_experimental,
    deliveryWindowStart: cbMeta.delivery_window_start,
    deliveryWindowEnd: cbMeta.delivery_window_end,
    timeBeginPacking: cbMeta.time_begin_packing,
  }
}

export function transformOrderPreview(
  order: OrderPreviewResponse
): OrderPreview {
  const transformed: OrderPreview = {
    id: order.id,
    priority: order.priority,
    priorityReasons: order.priority_reasons ?? [],
    orderTypes: order.product_groups,
    timeBeginPacking: order.time_begin_packing,
    timeReceived: order.time_created,
    timeScheduled: order.time_scheduled,
    deliveryWindowStart: order.delivery_window_start,
    deliveryWindowEnd: order.delivery_window_end,
    timeCustomerNotifiedCreditCheckFailed:
      order.time_customer_notified_credit_check_failed,
    creditCheckOverrideReason: order.credit_check_override_reason,
    slaTime: order.sla_time,
    facility: order.facility,
    recipientRoleId: order.facility?.id,
    status: mapStatus(order.status),
    numShipments: order.shipments ?? 0,
    notificationCount: order.notification_count ?? 0,
    comments: order.comments ?? '',
    deliverySiteName: order.delivery_site?.name ?? '',
    cbMeta: transformCBOrderMetadata(order.cb_meta),
    timePodSigned: order.time_pod_signed,
  }
  // compute substatus and override the status from the server
  const substatus = computeOrderSubstatus(transformed)
  return { ...transformed, status: substatus }
}

export function transformOrderStatusDetails(request: any): OrderStatusDetails {
  return {
    ...transformOrderPreview(request),
    orderer: transformOrderer(request.orderer),
    externalUuid: request.external_uuid,
    recipientRoleId: request.orderer.facility_id,
    facility: transformOrderStatusHealthFacility(request.facility),
    creditCheckOverrideReason: request.credit_check_override_reason,
    timeCustomerNotifiedConfirmed:
      request.time_customer_notified_order_confirmed,
    timeCustomerNotifiedOutOfStock:
      request.time_customer_notified_order_out_of_stock,
    timeCustomerNotifiedCreditCheckFailed:
      request.time_customer_notified_credit_check_failed,
    packages: request.packages.map(transformPackageStatusDetails),
    deliveryConfirmationCode: isNil(request.delivery_confirmation_code)
      ? undefined
      : transformDeliveryConfirmationCode(request.delivery_confirmation_code),
  }
}

export function transformPackageStatusDetails(
  request: any
): PackageStatusDetails {
  return {
    id: request.id,
    packageId: request.package_id,
    shipmentId: request.shipment_id,
    deliverySiteName: request.delivery_site || request.facility,
    flightId: request.flight_id,
    zipNumber: request.zip_number,
    timeAssignedFlightId: request.time_assigned_flight_id,
    timeLaunched: request.time_launched,
    timeApproachingDelivery: request.time_approaching_delivery,
    timeMissionFailure: request.time_mission_failure,
    timeFlightFailure: request.time_flight_failure,
    timeDelivered: request.time_delivered,
    timeCustomerNotifiedLaunched: request.time_customer_notified_launched,
    timeCustomerNotifiedApproachingDelivery:
      request.time_customer_notified_approaching_delivery,
    timeCustomerNotifiedDelivered: request.time_customer_notified_delivered,
    timeCustomerNotifiedFailure: request.time_customer_notified_failure,
  }
}

export function transformRequestedProduct(request: any): RequestedProduct {
  return {
    id: request.id,
    priority: request.priority,
    product: request.product,
    numFulfillingUnits: request.num_fulfilling_units,
    attributes: mapValues(request.attributes, transformAttribute),
    packaged: request.packaged,
    cancelation: request.cancelation,
    quantityConfirmed: request.quantity_confirmed,
    quantityPending: request.quantity_pending,
    quantityCanceled: request.quantity_canceled,
    isActive: request.is_active,
    timeCreated: request.time_created,
  }
}

export function transformBasicPackage(pack: any): BasicPackage {
  return {
    id: pack.id,
    weight: pack.weight,
    packageId: pack.package_id,
    units: pack.units?.map(transformUnit) ?? [],
    comments: pack.comments,
    deliverySite: pack.delivery_site
      ? transformDeliverySite(pack.delivery_site)
      : undefined,
    facility: transformHealthFacility(pack.facility),
    nominalStatus: pack.nominal_status,
    zipNumber: pack.zip_number,
    flightId: pack.flight_id,
    timeCreated: pack.time_created,
    timeDelivered: pack.time_delivered,
    timeOrderCreated: pack.time_order_created,
    timeLaunched: pack.time_launched,
    orderIds: pack.orders || [],
    orderer: isNil(pack.orderer) ? undefined : transformOrderer(pack.orderer),
    supplier: isNil(pack.supplier)
      ? undefined
      : transformSupplier(pack.supplier),
    pods: pack.pods?.map(transformPod) ?? [],
    deliveryConfirmationCode: isNil(pack.delivery_confirmation_code)
      ? undefined
      : transformDeliveryConfirmationCode(pack.delivery_confirmation_code),
    packageType: pack.package_type,
  }
}

export function transformPackage(pack: any): Package {
  return {
    ...transformBasicPackage(pack),
    requestedProducts: pack.requested_products.map(transformRequestedProduct),
  }
}

export function transformBasePackage(pkg: any): BasePackage {
  return {
    id: pkg.id,
    packageId: pkg.package_id,
    deliverySiteName: pkg.delivery_site_name,
  }
}

export function transformPod(pod: any): ProofOfDelivery {
  return {
    id: pod.id,
    supplier: isNil(pod.supplier) ? undefined : transformSupplier(pod.supplier),
    pdf: pod.pdf,
    signer: isNil(pod.user) ? undefined : transformUser(pod.user),
    timeSigned: pod.time_signed,
    timeDelivered: pod.time_delivered,
    timeModified: pod.time_modified,
    requiresSignature: pod.requires_signature || false,
  }
}

export function transformShipmentPreview(
  shipment: ShipmentPreviewResponse
): ShipmentPreview {
  return {
    id: shipment.id,
    order: transformOrderPreview(shipment.order),
    productGroups: shipment.product_groups,
    status: shipment.status,
    timeReceived: shipment.time_created,
    packers: shipment.packers.map(transformFulfillmentOperator),
    idInOrder: shipment.id_in_order,
    packageIds: shipment.package_ids,
    timeScheduled: shipment.time_scheduled,
    launchWindowStart: shipment.launch_window_start,
    launchWindowEnd: shipment.launch_window_end,
    launchIsUrgentAfter: shipment.launch_is_urgent_after,
    timeBeginPacking: shipment.time_begin_packing,
    clearForLaunch: shipment.clear_for_launch,
    windSpeed: shipment.wind_speed,
    batteryDotRating: shipment.battery_dot_rating,
  }
}

export function transformShipmentPreviewUnits(
  shipment: any
): ShipmentPreviewUnits {
  return {
    id: shipment.id,
    package_id: shipment.package_id || '',
    timeReceived: shipment.time_created,
    locked: shipment.locked,
    shipmentUnits: shipment.shipment_units.map(transformShipmentUnit),
    status: shipment.status,
    packers: shipment.packers.map(transformFulfillmentOperator),
    timeScheduled: shipment.time_scheduled,
    rescheduleReason: shipment.reschedule_reason || '',
  }
}

export function transformShipment(shipment: any): Shipment {
  return {
    ...transformShipmentPreviewUnits(shipment),
    order: transformOrderPreview(shipment.order),
    status: shipment.status,
    timeReceived: shipment.time_created,
    timeStartedPacking: shipment.time_started_packing,
    timeCommitted: shipment.time_package_committed,
    packages: shipment.packages.map(transformPackage),
    idInOrder: shipment.id_in_order,
    recommendedPackageType: shipment.recommended_package_type,
    maxWeightG: shipment.max_weight_g,
  }
}

export function transformShipmentUnit(shipmentUnit: any): ShipmentUnit {
  return {
    id: shipmentUnit.id,
    requestedProduct: transformRequestedProduct(shipmentUnit.requested_product),
    shipmentId: shipmentUnit.shipment,
    unitId: shipmentUnit.unit_id,
    packaged: shipmentUnit.packaged,
  }
}

export function transformBasicUnitWithProduct(unit: any): BasicUnitWithProduct {
  return {
    id: unit.id,
    attributes: mapValues(unit.attributes, transformAttribute),
    derivedStatus: unit.derived_status,
    product: transformProduct(unit.product),
    status: unit.status,
    timeCreated: new Date(unit.time_created),
    timePackaged: new Date(unit.time_packaged),
  }
}

export function transformBasicUnit(unit: any): BasicUnit {
  return {
    id: unit.id,
    attributes: mapValues(unit.attributes, transformAttribute),
    derivedStatus: unit.derived_status,
    status: unit.status,
    timeCreated: new Date(unit.time_created),
    timePackaged: new Date(unit.time_packaged),
  }
}

export function transformBasicUnitWithReturnDate(
  unit: any
): BasicUnitWithReturnDate {
  return {
    ...transformBasicUnit(unit),
    productSku: unit.product,
    returnByDate: unit.return_by_date
      ? new Date(unit.return_by_date)
      : undefined,
  }
}

export function transformUnit(unit: any): Unit {
  return {
    ...transformBasicUnitWithProduct(unit),
    bundleSize: unit.bundle_size,
    bundleUnitNumber: unit.bundle_unit_number,
    comments: unit.comments,
    fulfillsRequest: unit.fulfills_request,
    receivedShipment: isNil(unit.received_shipment)
      ? undefined
      : transformReceivedShipment(unit.received_shipment),
    restrictedFacilityGroup: unit.restricted_facility_group,
    package: unit.package,
    unitBox: isNil(unit.unit_box) ? undefined : unit.unit_box,
  }
}

export function transformUnitBox(unitBox: any): UnitBox {
  return {
    ...unitBox,
    units: unitBox.units.map(transformUnit),
  }
}

export function transformAttributeValue(attribute: Attribute): AttributeValue {
  return attribute.type === AttributeType.DATE
    ? // DatePicker expects a Date parsed in local timezone.
      // new Date('YYYY-MM-DD') will parse the day in UTC, which DatePicker will render as the previous day
      // for GMT < 0 timezones. dayjs parses the ISO day in the current timezone which avoids this problem.
      dayjs(attribute.value).toDate()
    : attribute.value
}

export function transformAttributeValues(
  attributes: AttributeMap
): Record<string, AttributeValue> {
  return mapValues(attributes, transformAttributeValue)
}

export function transformPayloadAttribute(
  attribute: Attribute,
  attributeValue: AttributeValue
): AttributeValue {
  return attribute.type === AttributeType.DATE
    ? dayjs(attributeValue).format('YYYY-MM-DD')
    : attributeValue ?? null
}

export function transformReceivedShipment(
  receivedShipment: any
): ReceivedShipment {
  return {
    id: receivedShipment.id,
    shipmentId: receivedShipment.shipment_id,
    supplier: transformSupplier(receivedShipment.supplier),
    nestId: receivedShipment.received_nest_id,
    supplierOrderId: receivedShipment.supplier_order_id,
  }
}

export function transformReceivedShipmentSummary(
  receivedShipmentSummary: any
): ReceivedShipmentSummary {
  return {
    id: receivedShipmentSummary.id,
    shipmentId: receivedShipmentSummary.shipment_id,
    supplier: receivedShipmentSummary.supplier.name,
    products: receivedShipmentSummary.products.map((product: any) => ({
      sku: product.sku,
      name: product.name,
      attributes: mapValues(product.attributes, transformAttribute),
    })),
    units: receivedShipmentSummary.units.map((unit: any) => ({
      id: unit.id,
      timeCreated: unit.time_created,
      productSku: unit.product,
      creator: unit.creator,
    })),
  }
}

export function transformReturnedShipmentSummary(
  returnedShipment: any
): ReturnedShipmentSummary {
  return {
    id: returnedShipment.id,
    supplier: transformSupplier(returnedShipment.supplier),
    timeReturn: returnedShipment.time_return,
    timeNextReturn: returnedShipment.time_next_return,
    pdf: returnedShipment.pdf,
    units: returnedShipment.units.map((unit: any) => ({
      id: unit.id,
      timeCreated: unit.time_created,
      productSku: unit.product,
      creator: unit.creator,
      // Defaulting the return reason to expiration just in case
      // the return shipment existed before the new feature
      returnReason: unit.return_reason || ReturnReason.EXPIRATION,
    })),
  }
}

export function transformReturnedShipment(
  returnedShipment: any
): ReturnedShipment {
  return {
    id: returnedShipment.id,
    supplier: transformSupplier(returnedShipment.supplier),
    timeReturn: returnedShipment.time_return,
    timeNextReturn: returnedShipment.time_next_return,
    pdf: returnedShipment.pdf,
  }
}

interface SupplierPayload {
  id: number
  name: string
  email?: string
  phone_number?: string
  logo?: string
  require_order_id?: boolean
  supports_enhanced_returns?: boolean
}

export function transformSupplier(supplier: SupplierPayload): Supplier {
  return {
    id: supplier.id,
    name: supplier.name,
    phoneNumber: supplier.phone_number,
    email: supplier.email,
    logoUrl: supplier.logo,
    requireOrderId: supplier.require_order_id || false,
    supportsEnhancedReturns: supplier.supports_enhanced_returns || false,
  }
}

export function transformUnitAction(unitAction: any): UnitAction {
  return {
    name: unitAction[0],
    displayName: unitAction[1],
  }
}

export function transformUnitStatus(unitStatus: any): UnitStatus {
  return {
    name: unitStatus[0],
    displayName: unitStatus[1],
  }
}

export function transformProductGroup(productGroup: any): ProductGroup {
  return {
    name: productGroup.name,
    unitIdRegex: new RegExp(productGroup.unit_id_regex),
    lastUsedUnitId: productGroup.last_used_unit_id,
    productAttributes: mapValues(
      productGroup.product_attributes,
      transformAttribute
    ),
    unitAttributes: mapValues(productGroup.unit_attributes, transformAttribute),
    productAttributeValues: productGroup.product_attribute_values,
    possibleProductAttributeValues:
      productGroup.possible_product_attribute_values,
    products: productGroup.products,
  }
}

export function transformProduct(product: any): Product {
  return {
    // TODO(ivan): Use primary key when we separate out sku and id.
    id: product.sku,
    sku: product.sku,
    name: product.name,
    attributes: mapValues(product.attributes, transformAttribute),
    requestedProductAttributes: mapValues(
      product.requested_product_attributes,
      transformAttribute
    ),
    unitAttributes: mapValues(product.unit_attributes, transformAttribute),
    groups: product.groups,
    substitutes: product.substitutes,
    receiveType: product.received_as_box ? ReceiveType.BOX : ReceiveType.UNIT,
    isActive: product.is_active,
  }
}

export function transformAttribute(attr: any): Attribute {
  return {
    changeable: attr.changeable,
    name: attr.name,
    type: attr.type,
    value: attr.value,
    displayName: attr.display_name,
    roles: attr.roles,
  }
}

export function transformDeliverySite(site: any): DeliverySite {
  return {
    id: site.id,
    dropPointId: site.drop_point_id,
    name: site.name,
    estimatedTimeToDelivery: site.estimated_time_to_delivery,
    lastUpdated: site.last_updated,
    status: site.status,
    isGrounded: site.is_grounded,
  }
}

export function transformDeliverySitePayload(
  site: DeliverySite
): NewDeliverySite {
  return {
    id: site.id,
    drop_point_id: site.dropPointId,
    name: site.name,
    estimated_time_to_delivery: site.estimatedTimeToDelivery,
    last_updated: site.lastUpdated,
    is_grounded: site.isGrounded,
  }
}

export function transformOrderStatusHealthFacility(
  facility: any
): OrderStatusHealthFacility {
  return {
    id: facility.id,
    name: facility.name,
    email: facility.email,
    district: facility.district,
    creditCheckStatus: facility.credit_check_status,
    timeLastCreditCheck: facility.time_last_credit_check,
    smsNotificationsEnabled: facility.sms_notifications_enabled,
  }
}

export function transformHealthFacility(facility: any): HealthFacility {
  return {
    ...transformHealthFacilityPreview(facility),
    address: facility.address,
    addressExtended: facility.address_extended,
    region: facility.region,
    postcode: facility.postcode,
    country: facility.country,
    email: facility.email,
    notificationTypes: facility.notification_types ?? [],
    smsNotificationsEnabled: facility.sms_notifications_enabled,
    billableEntity: facility.billable_entity,
    status: facility.status,
    statusReason: facility.status_reason,
  }
}

export function transformHealthFacilityGroup(
  facilityGroup: any
): HealthFacilityGroup {
  return {
    id: facilityGroup.id,
    name: facilityGroup.name,
    orderableProducts: facilityGroup.orderable_products,
  }
}

export function transformHealthFacilityPreview(
  facility: any
): HealthFacilityPreview {
  return {
    id: facility.id,
    organizationId: facility.id,
    deliverySites: facility.delivery_sites.map(transformDeliverySite),
    directDeliverySiteId: facility.direct_delivery_site,
    groups: facility.groups.map((groupId: number) => groupId.toString()),
    name: facility.name,
    notes: facility.notes,
    zone: facility.zone,
    healthSystemCode: facility.health_system_code,
    district: facility.district,
    region: facility.region,
    creditCheckStatus: facility.credit_check_status,
    timeLastCreditCheck: facility.time_last_credit_check,
    creditCheckOverrideReason: facility.credit_check_override_reason,
    safetyCheckStatus: facility.safety_check_status,
    timeLastSafetyCheck: facility.time_last_safety_check,
    status: facility.status,
  }
}

export function transformFacilityStock(facilityStock: any): FacilityStock {
  return {
    id: facilityStock.facility,
    productId: facilityStock.product,
    stockCount: facilityStock.stock,
  }
}

export function transformFulfillmentOperator(
  operator: any
): FulfillmentOperator {
  return {
    ...transformUser(operator),
    displayName: operator.display_name,
  }
}

export function transformHealthFacilityPayload(
  facility: HealthFacility
): NewHealthFacility {
  return {
    delivery_sites: facility.deliverySites.map((site) => site.id),
    direct_delivery_site: facility.directDeliverySiteId ?? null,
    name: facility.name,
    notes: facility.notes,
    address: facility.address,
    address_extended: facility.addressExtended,
    zone: facility.zone ?? undefined,
    health_system_code: facility.healthSystemCode ?? undefined,
    district: facility.district,
    region: facility.region,
    postcode: facility.postcode,
    country: facility.country,
    id: facility.id,
    email: facility.email,
    groups: facility.groups,
    credit_check_status: facility.creditCheckStatus,
    notification_types: facility.notificationTypes ?? [],
    sms_notifications_enabled: facility.smsNotificationsEnabled,
    safety_check_status: facility.safetyCheckStatus ?? undefined,
    status: facility.status,
    status_reason: facility.statusReason ?? null,
  }
}

export function transformContact(contact: any): Contact {
  return {
    id: contact.id,
    name: contact.name,
    phoneNumber: contact.phone_number,
    email: contact.email,
    contactType: contact.contact_type,
    isDefaultMsgRecipient: contact.is_default_msg_recipient ?? false,
    facilityId: contact.facility,
    district: contact.district,
  }
}

export function transformContactPayload(contact: Contact): ContactPayload {
  return {
    id: contact.id || undefined,
    name: contact.name,
    phone_number: contact.phoneNumber,
    email: contact.email,
    contact_type: contact.contactType,
    is_default_msg_recipient: contact.isDefaultMsgRecipient ?? false,
    facility: contact.facilityId,
    district: contact.district,
  }
}

export function transformNotification(notification: any): Notification {
  return {
    id: notification.id,
    entityId: notification.entity_id,
    notificationType: notification.notification_type,
    message: notification.message,
    timestamp: notification.timestamp,
    metadata: notification.metadata,
  }
}

export function transformFacilitySummary(summary: any): DailyFacilitySummary {
  return {
    id: summary.id,
    name: summary.name,
    email: summary.email,
    district: summary.district,
    orders: summary.orders.map((order: any) => ({
      id: order.id,
      pods: order.pods.map(transformPod),
      packages: order.packages.map(transformBasePackage),
      productGroups: order.product_groups,
    })),
  }
}

export function transformLocationSummary(summary: any): DailyLocationSummary {
  return {
    id: summary.id,
    name: summary.name,
    facilityIds: summary.facility_ids ?? [],
    orders: summary.orders.map((order: any) => ({
      id: order.id,
      pods: order.pods.map(transformPod),
      packages: order.packages.map(transformBasePackage),
      productGroups: order.product_groups,
    })),
  }
}

export function transformDeliveryConfirmationCode(
  code: any
): DeliveryConfirmationCode {
  return {
    code: code.code,
    expiresAt: code.expires_at,
  }
}

export function transformFacilityProductLimit(
  productLimitResponse: any
): ProductLimitsCreationResponse {
  const {
    received_shipment_id = '',
    failed_facilities = [],
    failed_products = [],
    limits_map = {},
  } = productLimitResponse.data || {}
  return {
    receivedShipmentId: received_shipment_id,
    failedFacilities: failed_facilities,
    failedProducts: failed_products,
    limitsMap: JSON.parse(limits_map),
  }
}

export interface ProductLimitsCreationResponse {
  receivedShipmentId: number
  limitsMap?: Record<string, Record<string, number>>
  failedFacilities: string[]
  failedProducts: string[]
}
