import { ProductGroup } from 'src/constants'
import { BloodUsePerUnitRequest } from 'src/models/blood_use'
import Contact from 'src/models/contact'
import { DeliveryConfirmationCode } from 'src/models/delivery'
import { PackageStatusDetails } from 'src/models/package'
import { Attribute, AttributeMap } from 'src/models/product'
import { Orderer } from 'src/models/users'

import {
  DeliverySite,
  HealthFacilityLocation,
  OrderStatusHealthFacility,
} from './sites'

/** Keep these in sync with backend-django/ordering/models/order.py. */
export enum Priority {
  UNKNOWN = -1,
  EMERGENCY = 0,
  RESUPPLY = 1,
  SCHEDULED = 2,
  FAST = 3,
  CUSTOM_WINDOW = 4,
}

export const PriorityName: Record<Priority, string> = {
  [Priority.UNKNOWN]: 'unknown',
  [Priority.EMERGENCY]: 'Emergency',
  [Priority.RESUPPLY]: 'Resupply',
  [Priority.SCHEDULED]: 'Scheduled',
  [Priority.FAST]: 'Fast',
  [Priority.CUSTOM_WINDOW]: 'Custom Window',
}

// Keep these in sync with Ordering Services (CB) /frontend/customer-backend-react-query/enums/order.ts
export enum PriorityNext {
  UNKNOWN = -1,
  EMERGENCY = 0,
  FAST = 1,
  SCHEDULED = 2,
  EOD = 3,
}
export const PriorityNextName: Record<PriorityNext, string> = {
  [PriorityNext.UNKNOWN]: 'unknown',
  [PriorityNext.EMERGENCY]: 'Emergency',
  [PriorityNext.FAST]: 'Fast',
  [PriorityNext.SCHEDULED]: 'Precise Window',
  [PriorityNext.EOD]: 'End of Day',
}

export enum PaymentProgram {
  FREE = 'Free (FMCH)',
  INSURED = 'Insured (BHCPF)',
  PAID = 'Paid (DRF)',
}

export enum Status {
  DRAFT = 'draft',
  UNSYNCED = 'unsynced',
  CANCELED = 'canceled',
  AWAITING_CREDIT_CHECK = 'awaiting_credit_check',
  FAILED_CREDIT_CHECK = 'failed_credit_check',
  NEEDS_PAYMENT = 'needs_payment',
  NEEDS_NOTIFICATION = 'needs_notification',
  AWAITING_RESTOCK = 'awaiting_restock',
  NEEDS_CONFIRMATION = 'needs_confirmation',
  CONFIRMED = 'confirmed',
  DELIVERED = 'delivered',
  NEEDS_RECEIPT_CONFIRMATION = 'needs_receipt_confirmation',
}

export interface CreditCheckOverrideReason {
  id: number
  value: string
  label: string
}

export interface CancelationReason {
  label: string
  value: string
}

export interface PriorityReason {
  label: string
  value: string
  priority: number
}

export interface OffNominalReason {
  id: number
  label: string
  value: string
}

export interface OrderMethod {
  id: number
  method: string
  is_active: boolean
}

export interface RequestedProductValidationError {
  id: number
  unapproved_quantity: number
}

export interface RequestedProduct {
  id?: number
  priority: Priority
  product: string
  numFulfillingUnits?: number // TODO (strisorus): we should standardize this to always be available
  attributes: AttributeMap
  packaged: boolean
  cancelation?: string
  quantityConfirmed: number
  quantityPending: number
  quantityCanceled: number
  timeCreated?: string
  isActive: boolean
}

export interface CBOrderMetadata {
  priorityExperimental: PriorityNext
  deliveryWindowStart: string
  deliveryWindowEnd: string
  timeBeginPacking: string
}

export interface OrderPreview {
  id: string
  priority: Priority
  timeScheduled?: string
  deliveryWindowStart?: string
  deliveryWindowEnd?: string
  timeBeginPacking?: string
  slaTime: string
  orderTypes: ProductGroup[]
  timeReceived: string
  facility: HealthFacilityLocation
  // For backwards compatibility, use the facility id for look up in HealthFaciltyStore.
  recipientRoleId?: number
  creditCheckOverrideReason: string | null
  timeCustomerNotifiedCreditCheckFailed: string | null
  status: Status
  numShipments?: number
  notificationCount?: number
  comments?: string
  priorityReasons?: string[]
  deliverySiteName?: string
  cbMeta?: CBOrderMetadata | null
  timePodSigned?: string | null
}

export interface Order extends OrderPreview {
  externalUuid?: string // Only set for orders returned from Fulfillment
  orderer: Orderer
  orderMethod: string
  packageIds: string[]
  recipient?: Contact
  pharmacistName?: string
  pharmacistId?: string
  referringPhysicianId?: number
  requestedProducts: RequestedProduct[]
  requiresPayment: boolean
  deliveryWindowStart?: string
  deliveryWindowEnd?: string
  timeCustomerNotifiedReceived: string | null
  timeCustomerNotifiedConfirmed: string | null
  timeCustomerNotifiedOutOfStock: string | null
  timePaymentReceived: string | null
  offNominalReason?: string
  deliverySite?: number
  possibleDeliverySites: DeliverySite[]
  facilityCreditCheckStatus: string | null
  bloodUsesPerUnit?: BloodUsePerUnitRequest[]
  billableTo?: string
}

export interface OrderStatusDetails extends OrderPreview {
  facility: OrderStatusHealthFacility
  orderer: Orderer
  timeCustomerNotifiedConfirmed: string | null
  timeCustomerNotifiedOutOfStock: string | null
  timeCustomerNotifiedCreditCheckFailed: string | null
  packages: PackageStatusDetails[]
  deliveryConfirmationCode?: DeliveryConfirmationCode
  externalUuid?: string
}

export interface OrderValidation {
  name: string
  label: string
  complete: boolean
  errors: { [key: string]: string }
  extra: { [key: string]: any }
}

/**
 * Interfaces used by the Add Order flow, which serialize data slightly
 * differently.
 */
// TODO: delete
export interface NewRequestedProduct {
  priority: Priority
  product: string
  attributes: { name: string; value: Attribute }[]
}
